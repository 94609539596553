




























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import Card from '@/app/ui/components/Card/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import IllustrationCreateFaq from '@/app/ui/assets/ill_create_faq.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import {
  enumStatus,
  typeOptions,
  uploadTypeOptions,
  perPageOptions,
  datepickerPresets,
  activityOptions,
  SortType,
  SortFields,
} from '@/app/infrastructures/misc/Constants/manualAdjustBasicFee'
import { Utils } from '@/app/infrastructures/misc'
import Badge from '@/app/ui/components/Badge/index.vue'
import ModalNotes from '../components/Modals/ModalNotes/index.vue'
import ImageViewer from '@/app/ui/components/ImageViewer/index.vue'
import EyeIcon from '@/app/ui/assets/eye_filled.vue'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import PayrollController from '@/app/ui/controllers/PayrollController'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  ManualAdjustBasicFee,
  ManualAdjustBasicFeeHistory,
} from '@/domain/entities/Payroll'
import Loading from '@/app/ui/components/Loading/index.vue'
import { IOptions } from '@/app/infrastructures/misc/Constants/manualAdjustSaldo'
import {
  DataObject,
  HeaderObject,
  SortValueObject,
} from '@/app/ui/components/DataTableV2/type'

@Component({
  components: {
    Card,
    Button,
    IllustrationCreateFaq,
    DateTimePickerV2,
    TextInput,
    DropdownSelect,
    DataTableV2,
    PaginationNav,
    Badge,
    ModalNotes,
    ImageViewer,
    EyeIcon,
    AscendingIcon,
    LoadingOverlay,
    Loading,
  },
})
export default class ManualAdjustBasicFeeList extends Vue {
  controller = PayrollController

  enumSortFields = SortFields

  headers = [
    this.headerCellMapper(
      'ID Transaksi',
      '140px',
      true,
      this.enumSortFields.ID,
      '10px'
    ),
    this.headerCellMapper('Tipe Upload', '160px'),
    this.headerCellMapper('Nama File', '240px'),
    this.headerCellMapper('Dikirim Oleh', '245px'),
    this.headerCellMapper(
      'Tanggal Kirim',
      '240px',
      true,
      this.enumSortFields.CREATED_AT,
      '20px'
    ),
    this.headerCellMapper('Nama Kurir', '220px'),
    this.headerCellMapper('Nomor STT', '180px'),
    this.headerCellMapper('Tipe Pengiriman', '160px'),
    this.headerCellMapper('Tipe', '160px'),
    this.headerCellMapper('Aktivitas', '140px'),
    this.headerCellMapper('Jumlah', '140px'),
    this.headerCellMapper('Status', '172px'),
    this.headerCellMapper('Diterima Oleh', '245px'),
    this.headerCellMapper(
      'Tanggal Diterima',
      '240px',
      true,
      this.enumSortFields.APPROVED_AT,
      '20px'
    ),
    this.headerCellMapper('Bukti Transaksi', '170px'),
    this.headerCellMapper('Catatan', '200px'),
  ]

  historyHeaders = [
    this.headerCellMapper('Nama File', '220px'),
    this.headerCellMapper('Dikirim Oleh', '225px'),
    this.headerCellMapper('Tanggal Submit', '200px'),
    this.headerCellMapper('Diterima/Tolak Oleh', '225px'),
    this.headerCellMapper('Tanggal Terima/Tolak', '199px'),
    this.headerCellMapper('Alasan', '225px'),
    this.headerCellMapper('Status', '180px'),
    this.headerCellMapper('Aksi', '141px'),
  ]

  enumStatus = enumStatus
  typeOptions = typeOptions
  uploadTypeOptions = uploadTypeOptions
  perPageOptions = perPageOptions
  datepickerPresets = datepickerPresets
  activityOptions = activityOptions

  parameters = {
    page: 1,
    perPage: this.perPageOptions[0].value,
    search: '',
    date: {
      start: <Date | null>null,
      end: <Date | null>null,
    },
    type: this.typeOptions[0],
    uploadType: this.uploadTypeOptions[0],
    sort: <SortValueObject>{
      direction: '',
      name: '',
    },
  }

  historyParameters = {
    page: 1,
    perPage: 5,
  }

  imageUrls: string[] = []

  modalNotes = {
    visible: false,
    notes: '',
  }
  imageViewerVisible = false

  tableData: DataObject[][] = []
  historyTableData: DataObject[][] = []

  mounted(): void {
    this.fetchList(true)
    this.fetchHistoryList()
  }

  get params(): Record<string, string | number> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      activity: this.parameters.type.value,
      insertType: this.parameters.uploadType.value,
      timeZone: encodeURIComponent(
        Utils.formatDateWithIDLocale(new Date().toISOString(), 'Z')
      ),
      dateFrom: this.parameters.date.start
        ? Utils.formatDateWithIDLocale(
            this.parameters.date.start?.toISOString(),
            'YYYY-MM-DD'
          )
        : '',
      dateTo: this.parameters.date.end
        ? Utils.formatDateWithIDLocale(
            this.parameters.date.end?.toISOString(),
            'YYYY-MM-DD'
          )
        : '',
      columnSort: <string>this.parameters.sort.name,
      sort: <string>this.parameters.sort.direction,
    }
  }

  get historyParams(): Record<string, string | number> {
    return {
      ...this.historyParameters,
    }
  }

  public fetchList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    const newParams = { ...this.params }

    if (!this.parameters.date.end && !this.parameters.date.start) {
      delete newParams.dateFrom
      delete newParams.dateTo
    }

    this.controller.getAllManualAdjustBasicFee(newParams)
  }

  public fetchHistoryList(): void {
    this.controller.getAllHistoryManualAdjustBasicFee(this.historyParams)
  }

  public handleModalNotes(visible: boolean, note: string): void {
    this.modalNotes.visible = visible
    this.modalNotes.notes = note
  }

  public onHandleImageViewer(visible: boolean, urls: string[]): void {
    if (urls) {
      if (urls.length !== 0) {
        this.imageUrls = urls.map(
          url => process.env.VUE_APP_STORAGE_BASE_URL + '/' + url
        )
      } else if (urls.length === 0) {
        this.imageUrls = []
      }
    }
    this.imageViewerVisible = visible
  }

  public downloadTemplate(): void {
    this.controller.downloadBasicFeeUploadBulkyTemplate()
  }

  public onSearch = Utils.debounce(() => {
    this.fetchList(true)
  }, 400)

  public onTypeFilter(value: IOptions): void {
    if (!value) {
      this.parameters.type = this.typeOptions[0]
    }

    this.fetchList(true)
  }

  public onUploadTypeFilter(value: IOptions): void {
    if (!value) {
      this.parameters.uploadType = this.uploadTypeOptions[0]
    }

    this.fetchList(true)
  }

  private formatTypeLabel(type?: string): string | undefined {
    const res = this.typeOptions.find(t => type === t.value)
    return res?.label
  }

  private formatUploadTypeLabel(type?: string): string | undefined {
    const res = this.uploadTypeOptions.find(t => type === t.value)
    return res?.label
  }

  private formatActivityValue(activity?: string): string | undefined {
    const res = this.activityOptions.find(a => activity === a.value)
    return res?.label
  }

  private formatAmount(amount?: number): string | undefined {
    if (!amount) {
      return '-'
    }

    if (amount < 0) {
      return `-${Utils.toRupiah(<number>amount * -1)}`
    } else if (amount >= 0) {
      return `${Utils.toRupiah(amount)}`
    }
  }

  public formatedStatus(
    status?: string
  ): {
    text: string
    color: string
  } {
    switch (status) {
      case this.enumStatus.APPROVED:
        return {
          text: 'Diterima',
          color: 'success',
        }
      case this.enumStatus.REJECTED:
        return {
          color: 'error-1',
          text: 'Ditolak',
        }
      default:
        return {
          color: 'warning',
          text: 'Menunggu',
        }
    }
  }

  public formattedTaskType(taskType: string): string {
    if (!taskType) {
      return '-'
    }
    if (taskType === 'DELIVERY') {
      return 'Delivery'
    }

    return 'Pick Up'
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    withSort?: boolean,
    sortName?: string,
    sortWidth?: string
  ): HeaderObject {
    return {
      title: <string>title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
      },
      withSort,
      sortName,
      sortStyle: {
        marginLeft: sortWidth,
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string
  ): DataObject {
    return {
      value: <string>value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
      },
    }
  }

  @Watch('controller.dataManualAdjustBasicFee')
  onDataManualAdjustBasicFeeChanged(data: ManualAdjustBasicFee[]): void {
    this.tableData = data.map(upload => {
      return [
        this.tableCellMapper(<number>upload.id, '140px'),
        this.tableCellMapper(
          <string>this.formatUploadTypeLabel(<string>upload.insertType),
          '160px'
        ),
        this.tableCellMapper(<string>upload.filename, '240px'),
        this.tableCellMapper(<string>upload.createdBy, '245px'),
        this.tableCellMapper(
          upload.createdAt
            ? [
                Utils.formatDateWithIDLocale(
                  <string>upload.createdAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    <string>upload.createdAt,
                    'HH:mm Z'
                  )
                ),
              ]
            : [],
          '240px'
        ),
        this.tableCellMapper(
          [
            `[${(<Record<string, string | number>>upload.courier).id}] ${
              (<Record<string, string | number>>upload.courier).fullname
            } (${(<Record<string, string | number>>upload.courier).type})`,
            <string>(
              (<Record<string, string | number>>upload.courier).phoneNumber
            ),
          ],
          '220px'
        ),
        this.tableCellMapper(<string>upload.packageId || '-', '180px'),
        this.tableCellMapper(
          this.formattedTaskType(<string>upload.taskType),
          '160px'
        ),
        this.tableCellMapper(
          <string>this.formatTypeLabel(<string>upload.type),
          '160px'
        ),
        this.tableCellMapper(
          <string>this.formatActivityValue(<string>upload.activity),
          '140px'
        ),
        this.tableCellMapper(
          <string>this.formatAmount(<number>upload.amount),
          '140px'
        ),
        this.tableCellMapper(<string>upload.status, '172px'),
        this.tableCellMapper(<string>upload.approvedBy || '-', '245px'),
        this.tableCellMapper(
          upload.approvedAt
            ? [
                Utils.formatDateWithIDLocale(
                  <string>upload.approvedAt,
                  'dddd DD MMM YYYY,'
                ),
                Utils.formatTimeZone(
                  Utils.formatDateWithIDLocale(
                    <string>upload.approvedAt,
                    'HH:mm Z'
                  )
                ),
              ]
            : [],
          '240px'
        ),
        this.tableCellMapper(<string[]>upload.meta || [], '170px'),
        this.tableCellMapper(<string>upload.notes, '200px'),
      ]
    })
  }

  @Watch('controller.dataHistoryManualAdjustBasicFee')
  onDataHistoryManualAdjustBasicFeeChanged(
    data: ManualAdjustBasicFeeHistory[]
  ): void {
    this.historyTableData = data.map(history => {
      return [
        this.tableCellMapper(history.filename || '-', '220px'),
        this.tableCellMapper(history.createdBy || '-', '225px'),
        this.tableCellMapper(
          history.createdAt
            ? Utils.formatTimeZone(
                Utils.formatDateWithIDLocale(
                  <string>history.createdAt,
                  'DD MMM YYYY, HH:mm Z'
                )
              )
            : '-',
          '200px'
        ),
        this.tableCellMapper(history.updatedBy || '-', '225px'),
        this.tableCellMapper(
          history.updatedAt
            ? Utils.formatTimeZone(
                Utils.formatDateWithIDLocale(
                  <string>history.updatedAt,
                  'DD MMM YYYY, HH:mm Z'
                )
              )
            : '-',
          '199px'
        ),
        this.tableCellMapper(history.reason || '-', '225px'),
        this.tableCellMapper(<string>history.statusApproval, '180px'),
        this.tableCellMapper(
          history.filepath ? `${history.id};${history.filepath}` : '-',
          '141px'
        ),
      ]
    })
  }
}
